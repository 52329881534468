import * as React from "react"
import LogoHeader from "../components/LogoHeader";
import PageContent from "../components/PageContent";
import Footer from "../components/Footer";
import Obfuscate from "react-obfuscate";
import { Metadata } from "../components/Metadata";


// markup
const Impressum = () => {
    return (
        <main>
            <Metadata title="Impressum"/>
            <LogoHeader />
            <PageContent>
                <h1>Impressum</h1>
                <h2>Informationspflichten nach dem ECG und Mediengesetz:</h2>
                <h3>Name und Anschrift</h3>
                <p>
                    Mag.a Susanne Hoffelner<br />
                    Linsengasse 57, 9020 Klagenfurt
                </p>
                <h3>Unternehmensgegenstand</h3>
                <p>
                    Praxis für Psychotherapie
                </p>
                <h3>Kontaktdaten</h3>
                <p>
                    E-Mail: <Obfuscate email="office@psychotherapie-hoffelner.at" /><br />
                    Telefon: <Obfuscate tel="+43 650 540 75 99" />
                </p>
                <h3>Mitgliedschaften</h3>
                <p>
                    Mitglied der ÖAS (Österreichische Arbeitsgemeinschaft für systemische Therapie und systemische Studien)
                </p>
                <h3>Anwendbare Rechtsvorschrit</h3>
                <p>
                    <a href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10010620">Psychotherapiegesetz</a>
                </p>
                <h3>Aufsichtsbehörde</h3>
                <p>
                    Bundesministerium für Soziales, Gesundheit, Pflege und Konsumentenschutz
                </p>
                <h3>Berufsbezeichnung</h3>
                <p>
                    Psychotherapeutin
                </p>
                <h3>Verleihungsstaat</h3>
                <p>
                    Österreich
                </p>
                <h3>Angaben zur Online-Streitbeteiligung</h3>
                <p>
                    Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU zu richten: http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.
                </p>
            </PageContent>
            <Footer />
        </main>
    )
}

export default Impressum
