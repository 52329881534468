import React from "react";
import styled from "styled-components";
import { ContentContainer, FullWidthBackground } from "../styles/layout";
import media from "styled-media-query";

const PageContentContainer = styled(FullWidthBackground)`
  color: ${(props) => props.theme.colors.base};        
`;

const Content = styled(ContentContainer)`
  text-align: left;
  padding-top: ${(props) => props.theme.spacing.lg};
   ${media.greaterThan("medium")`
      width: 50%;
      margin-left: auto;
      margin-right: auto;
  `}
`;


const PageContent = ({children}) => {
  return (
    <PageContentContainer>
      <Content>
        {children}
      </Content>
    </PageContentContainer>
  );
};


export default PageContent;
