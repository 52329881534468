import React from "react";
import styled from "styled-components";
import { ContentContainer, FullWidthBackground } from "../styles/layout";
import media from "styled-media-query";
import {Logo} from "./Logo";

const LogoHeaderContainer = styled(FullWidthBackground)`
`;

const Content = styled(ContentContainer)`
  width:180px;
  padding: ${(props) => props.theme.spacing.lg} 0 0;
`;


const LogoHeader = ({ }) => {
  return (
    <LogoHeaderContainer>
      <Content>
        <Logo/>  
      </Content>
    </LogoHeaderContainer>
  );
};


export default LogoHeader;
